/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'x': {
    width: 19,
    height: 19,
    viewBox: '0 0 19 19',
    data: '<path pid="0" d="M8.084 9.5L.294 1.71A1.002 1.002 0 011.71.293l7.79 7.79 7.79-7.79a1.002 1.002 0 011.417 1.417l-7.79 7.79 7.79 7.79a1.002 1.002 0 11-1.417 1.417l-7.79-7.79-7.79 7.79A1.002 1.002 0 11.293 17.29l7.79-7.79z" _fill="#818181" fill-rule="nonzero"/>'
  }
})
